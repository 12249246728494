<template>
  <LoadingState v-if="isLoading" />
  <div :class="$style.schedule" v-else>
    <a-row>
      <a-col :xs="24" :sm="24" :md="12" :xl="12">
        <h1>Schedule for {{ user.nama }}</h1>
      </a-col>
      <a-col
        :xs="24"
        :sm="24"
        :md="12"
        :xl="12"
        style="text-align: right"
        class="d-none d-md-block"
      >
        Week
        <a-select
          label-in-value
          :default-value="{ key: selectedWeek }"
          style="width: 22%"
          @change="handleChangeWeek"
          v-if="selectedWeek"
        >
          <template v-for="n in 24">
            <a-select-option v-if="n===currentWeek" :key="n" :value="n">{{n}} (this week)</a-select-option>
            <a-select-option :key="n" :value="n" v-else>{{n}}</a-select-option>
            <!-- <a-select-option :key="n" :value="n">{{ n }}</a-select-option> -->
          </template>
        </a-select>
      </a-col>
    </a-row>
    <div :id="$style.boxSchedule">
      <BoxSchedule
        :semesterFirstDay="semesterFirstDay"
        :slots="slots"
        :week="selectedWeek"
        :currentWeek="currentWeek"
      />
    </div>
  </div>
</template>
<script>
import BoxSchedule from '@/components/app/BoxSchedule'
import LoadingState from '@/components/app/LoadingState'
import moment from 'moment'

export default {
  components: {
    BoxSchedule,
    LoadingState,
  },
  data() {
    return {
      showClass: false,
      dataKelas: [],
      selectedWeek: null,
      currentWeek: null,
      semesterFirstDay: '',
    }
  },
  methods: {
    handleChangeWeek(value) {
      this.selectedWeek = value.key
    },
  },
  created() {
    this.$store.dispatch('slot/FETCH_TAHUN_AJARAN_ACTIVE')
      .then(res => {
        // console.log('res', res)
        this.semesterFirstDay = res
        this.selectedWeek = moment().diff(res, 'weeks') + 1
        this.currentWeek = moment().diff(res, 'weeks') + 1
      })
    this.$store.dispatch('slot/FETCH_SLOT_STUDENT', { idKelas: this.user.id_kelas })
    // this.selectedWeek = moment().diff(this.semesterFirstDay, 'weeks') + 1
    // this.currentWeek = moment().diff(this.semesterFirstDay, 'weeks') + 1
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading
    },
    slots () {
      return this.$store.state.slot.slots
    },
    // semesterFirstDay() {
    //   return this.$store.state.slot.semesterFirstDay
    // },
    user() {
      return this.$store.state.user.user
    },
  },
}
</script>
<style lang="scss" module>
@import "./style.module.scss";
</style>
