var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-block d-md-none"},[_c('a-form-item',[_c('a-select',{staticStyle:{"width":"100%","height":"40px"},attrs:{"size":"large","value":_vm.selectedWeek},on:{"change":_vm.handleChangeWeek}},_vm._l((24),function(index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v("Week "+_vm._s(index)+" "+_vm._s(index === _vm.currentWeek ? "(This week)" : ""))])}),1)],1),_c('a-form-item',[_c('a-select',{staticStyle:{"width":"100%","height":"40px"},attrs:{"size":"large","value":_vm.selectedDay},on:{"change":_vm.handleChangeDay}},_vm._l((_vm.daysMobile),function(day,index){return _c('a-select-option',{key:index,attrs:{"value":index}},[_vm._v(_vm._s(day)+", "+_vm._s(_vm.dayShowedSchedule("Mobile", index))+" "+_vm._s(_vm.dayShowedSchedule("Mobile", index) === _vm.todayDate ? "(Today)" : ""))])}),1)],1),_c('hr'),_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"timeScheduleMobile"},[_vm._l((_vm.times),function(time,index){return [(index === 4)?_c('div',{key:index,staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticStyle:{"text-align":"right","color":"#707788"}},[_vm._v(" BREAK ("+_vm._s(time)+") ")])]):(index === 9)?_c('div',{key:index,staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticStyle:{"text-align":"right","color":"#707788"}},[_vm._v(" BREAK ("+_vm._s(time)+") ")])]):_c('div',{key:index,staticClass:"scheduleAndTime time"},[_c('div',[_c('h3',[_vm._v(_vm._s(time))])])])]})],2),_c('div',{staticClass:"scheduleMobile"},[_vm._l((10),function(n){return [_c('div',{key:n,staticClass:"scheduleAndTime slotSchedule",style:({
              padding: n === 5 || n === 10 ? 0 : '',
              border: n === 5 || n === 10 ? 'none' : '',
            })},[(n === 5 || n === 10)?_c('div',{staticClass:"break"}):[_vm._l((_vm.dayToIndexSlots),function(slot,idxSlot){return [_vm._l((slot.sesis),function(session,idx){return [(
                      n === slot.posisi &&
                      session.week.week === _vm.selectedWeek &&
                      session.tanggal_sesi === _vm.selectedDayString
                    )?_c('div',{key:(idxSlot + " dan " + idx),staticClass:"boxSlot",on:{"click":function($event){$event.preventDefault();return _vm.toDetail(session.id)}}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":20}},[(_vm.role.includes('guruMapel'))?_c('h3',{style:({
                            color: _vm.colorTime(
                              slot.posisi,
                              _vm.selectedWeek,
                              slot.hari
                            ).textColor,
                          })},[_vm._v(" "+_vm._s(slot.kela.tingkat)+"-"+_vm._s(slot.kela.simbol)+" ")]):(_vm.role.includes('murid'))?_c('div',{staticClass:"subH3",style:({
                            color: _vm.colorTime(
                              slot.posisi,
                              _vm.selectedWeek,
                              slot.hari
                            ).textColor,
                          })},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()]),_c('a-col',{attrs:{"span":4}},[(session.zoom_setting)?_c('a-icon',{style:({
                            color: _vm.colorTime(
                              slot.posisi,
                              _vm.selectedWeek,
                              slot.hari
                            ).iconColor,
                            'font-size': '22px',
                          }),attrs:{"type":"video-camera"}}):[(
                              !_vm.colorTime(slot.posisi, _vm.selectedWeek, slot.hari)
                                .status
                            )?_c('div',{staticClass:"circleUndone",style:({
                              border: ("2px solid " + (_vm.colorTime(
                                  slot.posisi,
                                  _vm.selectedWeek,
                                  slot.hari
                                ).iconColor)),
                            })}):_c('a-icon',{style:({
                              color: _vm.colorTime(
                                slot.posisi,
                                _vm.selectedWeek,
                                slot.hari
                              ).iconColor,
                              'font-size': '22px',
                            }),attrs:{"type":"check-circle"}})]],2)],1),(_vm.role.includes('guruMapel'))?_c('div',{staticClass:"subH3"},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()],1):_vm._e()]})]})]],2)]})],2)])],1),_c('div',{staticClass:"d-none d-md-block"},[_c('div',{staticClass:"scheduleContainer"},[_c('div',{staticClass:"headerSchedule"},[_c('div',{staticClass:"nestedHeaderSchedule"},[_vm._l((_vm.days),function(day,index){return [(day === '')?_c('div',{key:index}):[_c('div',{key:index,staticClass:"contentHeaderSchedule"},[_c('h3',[(
                      _vm.dayShowedSchedule('', index - 1) === _vm.todayDate &&
                      _vm.currentPosition !== -1 &&
                      _vm.currentPosition !== 100 &&
                      _vm.isClass
                    )?_c('div',{staticClass:"circleToday mt-1 mr-1"}):_vm._e(),_vm._v(" "+_vm._s(day)+" ")]),(_vm.dayShowedSchedule('', index - 1) === _vm.todayDate)?_c('div',{staticClass:"subH3-blue"},[_vm._v(" "+_vm._s(_vm.dayShowedSchedule("", index - 1))+" ")]):_c('div',{staticClass:"subH3"},[_vm._v(" "+_vm._s(_vm.dayShowedSchedule("", index - 1))+" ")])])]]})],2)]),_c('div',{staticClass:"timeSchedule"},[_vm._l((_vm.times),function(time,idx){return [(idx === 4)?_c('div',{key:idx,staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticStyle:{"text-align":"right","color":"#707788"}},[_vm._v(" BREAK ("+_vm._s(time)+") ")])]):(idx === 9)?_c('div',{key:idx,staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticStyle:{"text-align":"right","color":"#707788"}},[_vm._v(" BREAK ("+_vm._s(time)+") ")])]):_c('div',{key:idx,staticClass:"scheduleAndTime time"},[_c('div',[_c('h3',[_vm._v(_vm._s(time))])])])]})],2),_c('div',{staticClass:"schedules"},[_vm._l((50),function(n){return [_c('div',{key:n,staticClass:"scheduleAndTime slotSchedule",style:({
              padding: (n > 20 && n <= 25) || (n > 45 && n <= 50) ? 0 : '',
              border:
                (n > 20 && n <= 25) || (n > 45 && n <= 50) ? 'none' : '',
            })},[((n > 20 && n <= 25) || (n > 45 && n <= 50))?_c('div',{staticClass:"break"}):[(_vm.slots.length > 0)?[_vm._l((_vm.dayToIndexSlots),function(slot,idxSlot){return [_vm._l((slot.sesis),function(session,idx){return [(
                        n % 5 === slot.hari &&
                        Math.ceil(n / 5) === slot.posisi &&
                        session.week.week === _vm.week
                      )?_c('div',{key:(idxSlot + " dan " + idx),staticClass:"boxSlot",on:{"click":function($event){$event.preventDefault();return _vm.toDetail(session.id)}}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{attrs:{"span":20}},[(_vm.role.includes('guruMapel'))?_c('h3',{style:({
                              color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                .textColor,
                            })},[_vm._v(" "+_vm._s(slot.kela.tingkat)+"-"+_vm._s(slot.kela.simbol)+" ")]):(_vm.role.includes('murid'))?_c('div',{staticClass:"subH3",style:({
                              color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                .textColor,
                            })},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()]),_c('a-col',{attrs:{"span":4}},[(session.zoom_setting)?_c('a-icon',{style:({
                              color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                .iconColor,
                              'font-size': '22px',
                            }),attrs:{"type":"video-camera"}}):[(
                                !_vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                  .status
                              )?_c('div',{staticClass:"circleUndone",style:({
                                border: ("2px solid " + (_vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                    .iconColor)),
                              })}):_c('a-icon',{style:({
                                color: _vm.colorTime(slot.posisi, _vm.week, slot.hari)
                                  .iconColor,
                                'font-size': '22px',
                              }),attrs:{"type":"check-circle"}})]],2)],1),(_vm.role.includes('guruMapel'))?_c('div',{staticClass:"subH3"},[_vm._v(" "+_vm._s(slot.mata_pelajaran.nama)+" ")]):_vm._e()],1):_vm._e()]})]})]:_vm._e()]],2)]})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }